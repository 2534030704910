import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

window.addEventListener('load', ()=>{
    cardAnimations();
})

function cardAnimations() {
    const wrappers = document.querySelectorAll('.image-text-block .card-wrapper');

    wrappers.forEach(wrapper => {
        const image = wrapper.querySelector('.image-wrapper');
        const content = wrapper.querySelector('.content-wrapper');

        gsap.from(image, {
            scrollTrigger: wrapper,
            x: -800,
        })
        gsap.from(content, {
            scrollTrigger: wrapper,
            x: 800,
        })
    });
}
